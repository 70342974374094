<template>
    <div id="control_index_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_title">控价管理</div>
        </el-row>

        <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <template v-for="item in list">
                <el-row @click.native="router_to(item.link_url)" class="font_size2 cursor"
                       style="border-bottom: 1px solid #cccccc;padding:15px 0;">
                    <el-col :span="19"><span>{{item.name}}</span></el-col>
                    <el-col :span="5" style="text-align:right;" class="font_size1"><i class="el-icon-arrow-right"></i></el-col>
                </el-row>
            </template>
        </el-row>
        
    </div>
</template>
<script>
import API from '../../api/api';

export default {
    data(){
        return{
            list:[{
                name:"控价客户列表",
                link_url:"/user_control",//路由地址
            },{
                name:"失败转成功配置",
                link_url:"/fail_state_set",//路由地址
            }]
            
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
       
    },
    //方法
    methods: {
        //跳转链接
        router_to(link_url){
            //跳转链接
            API.router_to(link_url);
        },
        //返回
        goBack() {
           API.router_to("/index");
        }
    }
};
</script>

<style scoped>

</style>